@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-radius: var(--cornerradius12);
  padding: 12px 14px;
  background: var(--cst-cards-category-background-default);

  @include fixedSize(100%, 65px);

  @include min-428-break {
    padding: 14px;
    border-radius: var(--cornerradius16);
    @include fixedSize(100%, 79px);
  }

  .left-slot {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .right-slot {
    @include fixedSize(108px, 36px);

    @include min-428-break {
      @include fixedSize(144px, 44px);
    }
  }
}

.variant {
  &--default {
    box-shadow: none;

    @include min-744-break {
      box-shadow: inset 0 0 0 2px var(--cst-cards-live-feed-outline-default);
    }
  }
}

.price-info {
  display: flex;
  flex-direction: column;
  gap: 2px;

  @include min-428-break {
    gap: 3px;
  }

  .top {
    display: flex;
    align-items: center;
    gap: 6px;

    @include min-428-break {
      gap: 8px;
    }
    .left-text {
      color: var(--cst-cards-item-page-newprice);
      @include typography-m;
      @include weight-bold;

      @include min-428-break {
        @include typography-xl;
      }
    }

    .right {
      display: flex;
      gap: 6px;
      align-items: center;

      @include min-428-break {
        gap: 8px;
      }

      &-text {
        color: var(--cst-diagramtooltip-tagtext-primary);
        @include typography-s;
        @include weight-semi-bold;

        @include min-428-break {
          @include typography-m;
        }
      }

      &-icon {
        svg,
        span {
          @include singleFixedSize(inherit);
        }
        color: var(--global-red600);
        @include singleFixedSize(16px);
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    gap: 6px;

    .text {
      color: var(--cst-cards-category-subtitle-default);
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }

    .icon {
      color: var(--cst-cards-category-icon-default);
      @include singleFixedSize(14px);
      display: flex;
      justify-content: center;
      align-items: center;
      svg,
      span {
        @include singleFixedSize(inherit);
      }

      @include min-428-break {
        @include singleFixedSize(18px);
      }
    }
  }
}
